
export default {
  data() {
    return {
      isCalendlyActive: false,
    };
  },
  methods: {
    activeCalendly() {
      this.isCalendlyActive = false;
      this.$nextTick(() => {
        this.isCalendlyActive = true;
      });
    },
  },
};
