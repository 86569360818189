
export default {
  props: {
    articles: {
      type: Array,
      default: () => [
        {
          image: null,
          category: null,
          url: null,
          title: null,
          abstract: null,
        },
      ],
    },

    withAdv: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    articlesWithEbook() {
      if (this.withAdv) {
        const newArticles = this.articles;
        const item = {
          image: "aside-rs.webp",
          url: null,
        };
        newArticles.splice(2, 0, item);
        return newArticles;
      } else {
        return this.articles;
      }
    },
  },
};
