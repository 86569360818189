
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm";
import Articles from "~/assets/blog.json";

export default {
  data() {
    return {
      navigationItems: [
        {
          label:
            "Tout (" +
            [...Articles].filter(
              (article) => article.categorySlug !== "glossaire"
            ).length +
            ")",
          slug: "all",
          isActive: true,
        },
        {
          label: "Site Internet",
          slug: "site-internet",
          isActive: false,
        },
        {
          label: "Réseaux Sociaux",
          slug: "reseaux-sociaux",
          isActive: false,
        },
        {
          label: "Communication",
          slug: "communication",
          isActive: false,
        },
        {
          label: "Référencement Google",
          slug: "referencement-google",
          isActive: false,
        },
        {
          label: "Stratégie d'entreprise",
          slug: "strategie-dentreprise",
          isActive: false,
        },
      ],

      navigationObj: {
        "site-internet": "Site Internet",
        "reseaux-sociaux": "Réseaux Sociaux",
        communication: "Communication",
        "referencement-google": "Référencement Google",
        "strategie-dentreprise": "Stratégie d'entreprise",
      },

      navigationActive: "all",

      optionsGlide: {
        type: "carousel",
        perView: 3,
        gap: 40,
        breakpoints: {
          1023: {
            perView: 2,
            gap: 32,
          },
          767: {
            perView: 1,
          },
        },
      },

      indispensables: [
        {
          title:
            "Qu’est-ce qu’un CMS, Content Management System ? Le guide complet",
          url: "/blog/quest-ce-quun-cms-content-management-system/",
          picto: "indispensable-1.png",
        },
        {
          title: "Créer un Site Internet, le guide de A à Z [2024]",
          url: "/blog/creer-un-site-internet-2024/",
          picto: "indispensable-2.png",
        },
        {
          title: "Guide de A à Z – Tout savoir sur Instagram",
          url: "/blog/instagram/",
          picto: "indispensable-3.png",
        },
        {
          title: "Comment faire du référencement naturel (SEO) en 2024 ?",
          url: "/blog/comment-faire-du-referencement-naturel-seo-en-2024/",
          picto: "indispensable-4.png",
        },
        {
          title: "Comment faire du référencement payant (SEA) en 2024 ?",
          url: "/blog/comment-faire-du-referencement-payant-sea-en-2024/",
          picto: "indispensable-5.png",
        },
      ],

      glide: null,
    };
  },

  head() {
    return {
      title:
        "ALEO, toute l'actualité de la communication: SEO, Site Web, Réseaux Sociaux et entreprise",
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content:
            "ALEO, toute l'actualité de la communication: SEO, Site Web, Réseaux Sociaux et entreprise",
        },
      ],
    };
  },

  mounted() {
    this.initGlide();
  },

  beforeDestroy() {
    setTimeout(() => {
      this.glide.destroy();
    }, 1200);
  },

  methods: {
    fetchArticles(category, range) {
      const articles = [...Articles].filter(
        (article) => article.categorySlug !== "glossaire"
      );
      const newArticles = [];
      const num = range || articles.length;
      articles.forEach((article) => {
        if (category !== "all") {
          if (article.categorySlug === category) {
            newArticles.push(article);
          }
        } else {
          newArticles.push(article);
        }
      });

      return newArticles.slice(0, num);
    },

    initGlide() {
      import("@glidejs/glide/dist/css/glide.core.min.css");
      this.glide = new Glide("#glide-last-articles", this.optionsGlide).mount({
        Controls,
        Breakpoints,
      });
    },

    setCategory(category) {
      this.navigationItems.forEach((item) => {
        item.isActive = false;
        if (item.slug === category) {
          item.isActive = true;
        }
      });
      this.navigationActive = category;
    },
  },
};
